import auth from './modules/auth'
import dashboard from './modules/dashboard';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations'
export default {
    namespaced: true,
    modules: {auth, dashboard},
    state,
    actions,
    mutations,
    getters
}
