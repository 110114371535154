export default {
  logo: require('@@/assets/admin/images/atlas-logo.png'),
  print_logo: require('@@/assets/admin/images/print-logo.png'),
  print_font: require('@@/assets/fonts/Yekan_bakh/Yekan-Bakh-Heavy.ttf'),
  customer: {
    hasRole: true,
  },
  // product: {
  //   hasVariety: true,
  //   hasColor: true,
  //   hasSizeChart: true,
  //   hasNewsletters: true,
  //   hasSets: true,
  //   hasFlash: true,
  //   hasShortDescription: false,
  //   hasGifts: true,
  //   hasShowQuantity: false,
  //   defaultStatus: 'available',
  //   defaultChargeable: false,
  //   defaultLowStockQuantityWarning: null,
  //   showSkuAndBarcode: true,
  //   excelType1: true
  // },
  // sizeChart: {
  //   changeColumn: false, // کم و زیاد کردن ستون ها
  //   hasType: true // آیا سایز چارت تایپ دارد یا نه
  // },
  // gift: {
  //   active: false
  // },
  // hasInstagram: true,
  // physicalShop: {
  //   active: false,
  //   hasSellers: true,
  //   printPrice: 'rial',
  //   dontShowFullNameInPrint: true
  // },
  // shipping: {
  //   hasVip: true
  // },
  // withdraw: {
  //   active: true
  // },
  // shippingExcel: {
  //   active: true
  // },
  // menu: {
  //   hasIcon: true
  // }
  
  product: {
    hasVariety: true,
    hasColor: true,
    hasSizeChart: true,
    hasNewsletters: true,
    hasSets: true,
    hasFlash: true,
    hasShortDescription: false
  },
  sizeChart: {
    changeColumn: false, // کم و زیاد کردن ستون ها
    hasType: true // آیا سایز چارت تایپ دارد یا نه
  },
  hasInstagram: true,
  flash: {
    hasMobileImage: true
  },
  physicalShop: {
    active: true
  },
  shipping: {
    hasVip: true
  }
}
