export default {
    baseUrl: 'https://api.elinorboutique.com/v1',
    title: 'فروشگاه الینور',
    colorLoading: 'var(--color-themeBlue)',
    firebase: {
        apiKey: 'AIzaSyB34EruUj_9cOCukdyheN_dJc-vHqXUlUc',
        authDomain: 'laravel-test-2af60.firebaseapp.com',
        databaseURL: 'https://laravel-test-2af60.firebaseio.com',
        projectId: 'laravel-test-2af60',
        storageBucket: 'laravel-test-2af60.appspot.com',
        messagingSenderId: "277730644327",
        appId: "1:277730644327:web:01ac2e391f955e48bd1fc2",
        measurementId: "G-JYX2QD79S1"
    }
}
