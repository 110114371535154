import {getCookie} from '@@/core/libraries/VueMethods'
import notification from "@@/core/components/shared/Notifications/classes/notification";
export default {
  initialized: (state) => {
    return Boolean(state["admin/settings"]);
  },
  getPermissions: (state) => {
    return state.permissions ? state.permissions : (getCookie('permissions') ? JSON.parse(getCookie('permissions')) : null)
  },
  getNotifications(state) {
    return  state.notifications ? state.notifications.map(item => new notification(item)) : null
}
}
