import configProvider from "@@/core/configProvider";

let menu = [
  {
    title: "داشبورد",
    icon: "feather-home",
    to: "/admin",
    children: [],
    permissions: ["public"],
  },
  {
    title: "اطلاعات پایه",
    icon: "feather-clipboard",
    to: "",
    permissions: [
      "read_area",
      "read_color",
      "read_size_chart",
      "read_brand",
      "read_unit",
    ],
    children: [
      {
        title: "مناطق",
        to: "",
        permissions: ["read_area"],
        children: [
          {
            title: "استان",
            to: "/admin/provinces",
            children: [],
            permissions: ["read_area"],
          },
          {
            title: "شهر",
            to: "/admin/cities",
            children: [],
            permissions: ["read_area"],
          },
        ],
      },
      {
        title: "رنگ ها",
        to: "/admin/color",
        children: [],
        permissions: ["read_color"],
      },
      {
        title: "سایز چارت",
        to: "/admin/size-chart",
        children: [],
        permissions: ["read_size_chart"],
      },
      {
        title: "برند ها",
        to: "/admin/brands",
        children: [],
        permissions: ["read_brand"],
      },
      {
        title: "واحد",
        to: "/admin/unit",
        children: [],
        permissions: ["read_unit"],
      },
    ],
  },
  {
    title: "محصولات",
    icon: "fe fe-shopping-bag",
    to: "",
    permissions: [
      "read_product",
      "read_category",
      "read_attribute",
      "read_specification",
      "read_coupon",
      "read_product_set",
    ],
    children: [
      {
        title: "محصول",
        to: "/admin/products",
        children: [],
        permissions: ["read_product"],
      },
      {
        title: "هدایا",
        to: "/admin/gifts",
        children: [],
        permissions: ["read_product"],
      },
      {
        title: "دسته بندی",
        to: "/admin/categories",
        children: [],
        permissions: ["read_category"],
      },
      {
        title: "ست ها",
        to: "/admin/sets",
        children: [],
        permissions: ["read_product_set"],
      },
      {
        title: "ویژگی ها",
        to: "/admin/attributes",
        children: [],
        permissions: ["read_attribute"],
      },
      {
        title: "مشخصات",
        to: "/admin/specifications",
        children: [],
        permissions: ["read_specification"],
      },
      {
        title: "کد تخفیف",
        to: "/admin/coupon",
        children: [],
        permissions: ["read_coupon"],
      },
      {
        title: "کمپین فروش",
        to: "/admin/flash",
        children: [],
        permissions: ["read_flash"],
      },
    ],
  },
  {
    title: "محتوا",
    icon: "fe fe-layers",
    to: "",
    permissions: [
      "read_post",
      "read_slider",
      "read_menu",
      "read_instagram",
      "read_faq",
    ],
    children: [
      {
        title: "مطلب",
        to: "",
        permissions: ["read_post-category", "read_post"],
        children: [
          {
            title: "مطالب",
            to: "/admin/posts",
            children: [],
            permissions: ["read_post"],
          },
          {
            title: "دسته بندی",
            to: "/admin/post-categories",
            children: [],
            permissions: ["read_post-category"],
          },
        ],
      },
      {
        title: "اسلایدر",
        to: "/admin/slider",
        children: [],
        permissions: ["read_slider"],
      },
      {
        title: "منو",
        to: "/admin/menu",
        children: [],
        permissions: ["read_menu"],
      },
      {
        title: "بنر",
        to: "/admin/position",
        children: [],
        permissions: ["read_advertise"],
      },
      {
        title: "صفحات",
        to: "/admin/pages",
        children: [],
        permissions: ["read_page"],
      },
      {
        title: "اینستاگرام",
        to: "/admin/instagram",
        children: [],
        permissions: ["read_instagram"],
      },
      {
        title: "سوالات متداول",
        to: "/admin/faqs",
        children: [],
        permissions: ["read_faq"],
      },
      {
        title: "محصولات پیشنهادی",
        to: "/admin/recommendation-groups",
        children: [],
        permissions: ["recommendation"],
      },
    ],
  },
  {
    title: "نظرات",
    icon: "fe fe-message-square",
    to: "",
    permissions: ["read_productComment", "read_comment", "read_contact"],
    children: [
      {
        title: "نظرات محصول",
        to: "/admin/comments",
        children: [],
        permissions: ["read_productComment"],
      },
      {
        title: "نظرات مطالب",
        to: "/admin/post-comment",
        children: [],
        permissions: ["read_comment"],
      },
      {
        title: "تماس با ما",
        to: "/admin/contact",
        children: [],
        permissions: ["read_contact"],
      },
    ],
  },
  {
    title: "کاربران",
    icon: "fe fe-users",
    to: "",
    permissions: ["read_admin", "read_role", "read_customer", "read_withdraw"],
    children: [
      {
        title: "مدیران",
        to: "",
        permissions: ["read_admin", "read_role"],
        children: [
          {
            title: "مدیران",
            to: "/admin/admins",
            children: [],
            permissions: ["read_admin"],
          },
          {
            title: "نقش ها",
            to: "/admin/roles",
            children: [],
            permissions: ["read_role"],
          },
        ],
      },
      {
        title: "مشتریان",
        to: "/admin/customers",
        children: [],
        permissions: ["read_customer"],
      },
      {
        title: "نقش مشتریان",
        to: "/admin/customer_roles",
        children: [],
        permissions: configProvider.get("customer.hasRole")
          ? ["read_customer"]
          : ["#####"],
      },
      {
        title: "فروشندگان",
        name: "sellers",
        to: "/admin/sellers",
        children: [],
        permissions: configProvider.get("physicalShop.hasSellers")
          ? ["read_seller"]
          : ["#####"],
      },
      {
        title: "تراکنش های کیف پول",
        name: "transactions",
        to: "/admin/transactions",
        children: [],
        permissions: ["read_customer"],
      },
      {
        title: "برداشت های کیف پول",
        name: "withdraws",
        to: "/admin/withdraws",
        children: [],
        permissions: configProvider.get("withdraw.active")
          ? ["read_withdraw"]
          : ["#####"],
      },
    ],
  },
  {
    title: "حمل و نقل",
    icon: "fe fe-truck",
    to: "/admin/shipping",
    children: [],
    permissions: ["read_shipping"],
  },
  {
    title: "انبار",
    icon: "feather-box",
    to: "/admin/store",
    children: [],
    permissions: ["read_store"],
  },
  {
    title: "سفارشات",
    icon: "fe fe-package",
    to: "/admin/order",
    children: [
      {
        title: "تغییر وضعیت سفارشات",
        icon: "fe fe-package",
        to: "/admin/changeOrders",
        children: [],
        permissions: ["read_order"],
      },
    ],
    permissions: ["read_order"],
  },
  {
    title: "فروشگاه حضوری",
    name: "shop",
    icon: "fe fe-slack",
    to: "/admin/shop",
    children: [],
    permissions: configProvider.get("physicalShop.active")
      ? ["mini_order"]
      : ["#####"],
  },
  {
    title: "گزارشات",
    icon: "fe fe-bar-chart-2",
    to: "",
    permissions: ["read_order"],
    children: [
      {
        title: "بازدیدها",
        to: "/admin/reports/visit",
        permissions: ["read_customer"],
      },
      {
        title: "مشتریان",
        to: "/admin/reports/customers",
        permissions: ["read_customer"],
      },
      {
        title: "محصولات",
        to: "/admin/reports/products",
        permissions: ["read_order", "read_product"],
      },
      // {
      //     title:'تخفیف',
      //     to:'/admin/reports/discount',
      //     permissions:['read_order', 'read_product'],
      // },
      {
        title: "تنوع ها",
        to: "/admin/reports/varieties",
        permissions: ["read_order", "read_product"],
      },
      {
        title: "کیف پول",
        to: "/admin/reports/wallets",
        permissions: ["read_customer", "read_order"],
      },
      {
        title: "سفارشات(تکی)",
        to: "/admin/reports/orders",
        permissions: ["read_order"],
      },
      {
        title: "پیامک (سبد خرید)",
        to: "/admin/reports/sms-report",
        permissions: ["read_order"],
      },
    ],
  },
  {
    title: "خبرنامه",
    icon: "fe fe-file-text",
    to: "",
    name: "newsletters",
    permissions: ["read_newsletters"],
    children: [
      {
        title: "خبرنامه",
        to: "/admin/newsletters",
        permissions: ["read_newsletters"],
      },
      {
        title: "اعضا خبرنامه",
        to: "/admin/member-newsletters",
        children: [],
        permissions: ["read_newsletters"],
      },
    ],
  },
  {
    title: "دیگر",
    icon: "fe fe-aperture",
    to: "",
    name: "other",
    permissions: [
      "htaccess",
      configProvider.get("shippingExcel.active") ? "read_order" : "##",
    ],
    children: [
      {
        title: "htaccess",
        to: "/admin/htaccess",
        permissions: ["htaccess"],
      },
      {
        title: "اکسل پست",
        to: "/admin/shipping_excels",
        permissions: [
          configProvider.get("shippingExcel.active") ? "read_order" : "##",
        ],
      },
    ],
  },
];

let removedChildren1 = [];
if (!configProvider.get("product.hasNewsletters")) {
  removedChildren1.push("newsletters");
}

let removedChildren2 = [];
if (!configProvider.get("product.hasColor")) {
  removedChildren2.push("/admin/color");
}
if (!configProvider.get("product.hasSizeChart")) {
  removedChildren2.push("/admin/size-chart");
}
// if(!configProvider.get('product.hasets')) {
//     removedChildren2.push('/admin/sets')
// }
if (!configProvider.get("product.hasFlash")) {
  removedChildren2.push("/admin/flash");
}
if (!configProvider.get("hasInstagram")) {
  removedChildren2.push("/admin/instagram");
}

if (!configProvider.get("product.hasGifts")) {
  removedChildren2.push("/admin/gifts");
}

let _menu = menu.filter((item) => {
  if (removedChildren1.includes(item.name)) {
    return false;
  }
  return true;
});
_menu.forEach((item) => {
  if (item.children) {
    item.children = item.children.filter((_item) => {
      if (removedChildren2.includes(_item.to)) {
        return false;
      }
      return true;
    });
  }
});

export default _menu;
