export default class notification {
  constructor(notification) {
    for (let [index, value] of Object.entries(notification)) {
      this[index] = value;
    }
    this.type = this.getType()
    if (typeof this.data === 'string') {
      this.data = JSON.parse(this.data)
    }
  }

  // اون چیزی که نشون میدیم و از این میگیریم
  /**
   * @return {{
   *   id: String,
   *   to: String,
   *   description: String,
   *   read: Boolean
   * }}
   */
  getNotification() {
    switch (this.type) {
      case "ProductUnavailableNotification":
        return {
          id: this.id,
          title: 'ناموجود',
          to: '/admin/products/edit/' + this.data.product_id,
          description: this.data.description,
          read: Boolean(this.read_at),
          created_at: this.created_at
        }
      case "order":
        return {
          id: this.id,
          title: 'سفارش جدید',
          to: '/admin/order/' + this.data.order_id,
          description: this.data.description,
          read: Boolean(this.read_at),
          created_at: this.created_at
        }
    }
  }

  /** گرفتن نوع نوتیفیکیشن
   * Types: SendProductAvailableNotification
   */
  getType() {
    switch (true) {
      case this.type.includes('ProductUnavailableNotification'):
        return 'ProductUnavailableNotification';
      default:
        return this.type;
    }
  }
}
