import axios from "axios";
import config from "@/config";
import * as VueMethods from "@@/core/libraries/VueMethods";
import { getCookie } from "@@/core/libraries/VueMethods";

axios.defaults.baseURL = config.baseUrl;
if (VueMethods.isPart("admin")) {
  if (getCookie("adminAuthorization")) {
    axios.defaults.headers.common["Authorization"] = getCookie(
      "adminAuthorization"
    );
  }
}

export default {
  namespaced: true,
  state: () => ({
    data: {},
  }),
  mutations: {
    setData(state, data) {
      state.data[data.key] = data.value;
    },
  },
  actions: {
    async fetchData({ commit }, requestName) {
      let response = null;
      response = await axios.get(`admin/dashboards/${requestName}`);
      commit("setData", { key: requestName, value: response.data.data });
      return response.data.data;
    },
  },
  getters: {
    getData(state) {
      return state.data;
    },
  },
};
