import {getCookie, deleteCookie} from '@@/core/libraries/VueMethods'
import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    admin: null
  }),
  mutations: {
    setAdmin(state, admin) {
      state.admin = admin
    }
  },
  actions: {
    async checkAuth({commit, state}) {
      if (getCookie('adminAuthorization')) {
        axios.defaults.headers.common["Authorization"] = this.getCookie('adminAuthorization')
        const response = await axios.post('admin/profile')
        commit('setAdmin', response.data.data.user);
        return response.data.data.user
      } else {
        this.$router.push('admin/login')
      }
    },
    async logout({commit,state}) {
      await axios.post('admin/logout');
      commit('setAdmin', null);
      deleteCookie('adminAuthorization')
      axios.defaults.headers.common["Authorization"] = ''
      return true
    }
  },
  getters: {
    isAuth(state) {
      return Boolean(state.admin);
    }
  }
}
