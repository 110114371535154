export default {
  data() {
    return {
    }
  },
  methods: {
    hasPermissions(permissions) {
      let passed = false;
      permissions.forEach(p => {
        if(p == 'public') {
          passed = true
        } else if(this.hasPermission(p)) {
          passed = true
        }
      });
      return passed
    },
    hasPermission(permission) {
      let permissions = this.$store.getters['admin/getPermissions'];
      if (!permissions) {
        return false;
      }
      return permissions.includes(permission);
    }
  }
}